/**
 * These annotations control how your component sizes
 * Learn more: https://www.framer.com/developers/components/auto-sizing
 *
 * @framerSupportedLayoutWidth auto
 * @framerSupportedLayoutHeight auto
 */
export default function GoogleReviewElfsight(props) {
    // This is a React component containing an Example component
    // Replace <Example /> with your own code

    return (
        <>
            <script
                src="https://static.elfsight.com/platform/platform.js"
                async
            ></script>
            <div
                className="elfsight-app-ff1725d5-6879-4708-93b2-0cf7eb1d791d"
                data-elfsight-app-lazy
            ></div>
        </>
    )
}

// Styles are written in object syntax
// Learn more: https://reactjs.org/docs/dom-elements.html#style
const containerStyle = {
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    overflow: "hidden",
}
